@use '@angular/material' as mat;

@include mat.core();

// Return a theme map where the foreground and background sub-maps have been augmented with additional keys.
@function sis-light-theme($primary, $accent, $warn) {
    $result: mat.m2-define-light-theme($primary, $accent, $warn);
    @return map-merge($result, (
            foreground: map-merge(map-get($result, foreground), (
                    hover-row-text: mat.m2-get-color-from-palette($primary, '100-contrast'),
                    hover-nav-text: mat.m2-get-color-from-palette($primary, '100-contrast'),
                    active-row-text: mat.m2-get-color-from-palette($primary, '400-contrast'),
                    active-nav-text: mat.m2-get-color-from-palette($primary, '400-contrast'),
            )),
            background: map-merge(map-get($result, background), (
                    hover-row: mat.m2-get-color-from-palette($primary, 100),
                    hover-nav-item: mat.m2-get-color-from-palette($primary, 100),
                    active-row: mat.m2-get-color-from-palette($primary, 400),
                    active-nav-item: mat.m2-get-color-from-palette($primary, 400),
            ))
    ))
}

// indigo-pink
$primary: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$warn: mat.m2-define-palette(mat.$m2-red-palette);
$typography: mat.m2-define-typography-config();

$dark-primary-text: mat.m2-get-contrast-color-from-palette($primary, 200);

$theme: mat.m2-define-light-theme((
        color: (primary: $primary, accent: $accent, warn: $warn),
        typography: $typography,
        density: 0
));

// Include all theme styles for the components.
@include mat.all-component-themes($theme);
