@use '@angular/material' as mat;
@import 'theme';

.fill-parent {
    width: 100%;
    height: 100%;
}

.sis-sidenav {
    width: 220px;
    height: 100%;
}

.sis-sidenav-content {
    width: calc(100% - 220px);
}

.side-nav-top-space {
    width: 100%;
    height: 50px;
    background-color: transparent;
}

.side-nav-mid-space {
    width: 100%;
    background-color: transparent;
}

.table-row {
    position: relative;
    cursor: pointer;
}

.table-row:hover {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    background: map-get($background, hover-row);

    .mat-mdc-cell {
        color: map-get($foreground, hover-row-text);
    }
}

.table-row:active {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    background: map-get($background, active-row);

    .mat-mdc-cell {
        color: map-get($foreground, active-row-text);
    }
}

.table-row .mat-icon, .mat-mdc-table .mat-icon {
    color: $dark-primary-text;
}

.ellipsized {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

button.mat-mdc-button {
    letter-spacing: normal;
}

button.mat-mdc-raised-button {
    letter-spacing: normal;
}

mat-select.mat-mdc-select {
    letter-spacing: normal;
    font-size: 14px;
}

mat-option.mat-mdc-option {
    letter-spacing: normal;
    font-size: 14px;
}

mat-form-field.mat-mdc-form-field {
    letter-spacing: normal;
    font-size: 14px;
}

app-select-language[color="white"] {
    mat-select, .mat-mdc-select-arrow {
        color: white;
    }
}

app-select-language[color="black"] {
    mat-select, .mat-mdc-select-arrow {
        color: black;
    }
}

.mat-mdc-select-arrow {
    color: black;
}

.mat-mdc-slider .mdc-slider__track {
    --mdc-slider-inactive-track-height: 3px;
}

.mat-mdc-slider .mdc-slider__track--inactive {
    --mdc-slider-inactive-track-height: 3px;
}

.mat-mdc-slider .mdc-slider__track--active {
    --mdc-slider-active-track-height: 3px;
    --mdc-slider-inactive-track-height: 3px;
}

.mat-mdc-nav-list .mat-mdc-list-item.mdc-list-item--activated {
    background-color: mat.m2-get-color-from-palette($primary) !important;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
    color: white !important;
}

.sis-card-title {
    height: 50px;
    display: flex;
    justify-items: flex-start;
    align-items: center;
    margin: 0 20px 0 20px
}

.sis-bold {
    font-weight: bold;
}

.action-button {
    width: 140px;
    margin: 2px;
}

html, body {
    height: 100%;
    min-height: 100%;
    margin: 0;
}

body {
    font-size: 14px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
}

.m-t-5 {
    margin-top: 5px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-l-4 {
    margin-left: 4px;
}

.m-l-5 {
    margin-left: 5px;
}

.m-l-15 {
    margin-left: 15px;
}

.m-l-20 {
    margin-left: 20px;
}

.m-l-30 {
    margin-left: 30px;
}

.m-l-50 {
    margin-left: 50px;
}

.m-r-5 {
    margin-right: 5px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-r-20 {
    margin-right: 20px;
}

.m-r-30 {
    margin-right: 30px;
}

.m-r-40 {
    margin-right: 40px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-15 {
    margin-bottom: 15px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-40 {
    margin-bottom: 40px;
}

.m-b-80 {
    margin-bottom: 80px;
}

.m-0 {
    margin: 0 0 0 0;
}

.m-auto {
    margin: auto;
}

.height-10 {
    height: 10px;
}

.height-50 {
    height: 50px;
}

.height-350 {
    height: 350px;
}

.height-85-p {
    height: 85%;
}

.min-height-10 {
    min-height: 10px;
}

.width-150 {
    width: 150px;
}

.width-250 {
    width: 250px;
}

.width-300 {
    width: 300px;
}

.width-450 {
    width: 450px;
}

.width-40-p {
    width: 40%;
}

.width-50-p {
    width: 50%;
}

.width-60-p {
    width: 60%;
}

.width-70-p {
    width: 70%;
}

.width-80-p {
    width: 80%;
}

.width-90-p {
    width: 90%;
}

.width-95-p {
    width: 95%;
}

.width-100-p {
    width: 100%;
}

.min-width-200 {
    min-width: 200px;
}

.max-width-200 {
    max-width: 200px;
}

.max-width-90-p {
    max-width: 90%;
}
